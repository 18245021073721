const Images = {
  dp: require("../images/dp.jpeg"),
  wabg: require("../images/wabg.png"),
  bc: require("../images/bryan_czar.jpg"),
  bm: require("../images/bryanmuli.jpg"),
  bmw: require("../images/bryanmuliw.jpg"),
  libby: require("../images/libby.jpg")
};

export default Images;
