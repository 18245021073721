export const NIRO = "NIRO";
export const SKILLS = "SKILLS";
export const SUBMIT_CONTACT = "SUBMIT_CONTACT";
export const SAY_HELLO = "SAY_HELLO";
export const SENDING = "SENDING";
export const GET_ERROR = "GET_ERROR";
export const RESUME_ABOUT = "RESUME_ABOUT";
export const RESUME_DEV = "RESUME_DEV";
export const RESUME_EDUCATION = "RESUME_EDUCATION";
export const RESUME_PROFILE = "RESUME_PROFILE";
export const RESUME_SKILLS = "RESUME_SKILLS";


